<template>
  <div class="page_content page_content_product">
    <ProductModal />
    <div class="content_header d-flex align-items-center">
      <span>商品管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div
          class="search_section"
          :class="{ 'd-none': tab_content !== 'list' }"
        >
          <div class="form-group">
            <select
              id="system"
              v-model="search.system"
              v-validate="'required'"
              name="system"
              class="form-control search_brand"
              :class="{'is-invalid': errors.has('search.system')}"
              data-vv-scope="search"
              @change="get_main_category($event.target.value)"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                系統
              </option>
              <option
                v-for="item in system_data"
                :key="item.systemCode"
                :value="item.systemCode"
              >
                {{ item.systemName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <select
              id="main_category"
              v-model="search.main_category"
              v-validate="'required'"
              name="main_category"
              class="form-control"
              :class="{'is-invalid': errors.has('search.main_category')}"
              data-vv-scope="search"
              @change="get_sub_category()"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                主分類
              </option>
              <option
                v-for="item in category_data"
                :key="item.categoryCode"
                :value="item.categoryCode"
              >
                {{ item.categoryName }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <select
              id="sub_category"
              v-model="search.sub_category"
              v-validate="'required'"
              name="sub_category"
              class="form-control"
              :class="{'is-invalid': errors.has('search.sub_category')}"
              data-vv-scope="search"
              @change="get_product_list()"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                次分類
              </option>
              <option
                v-for="item in subcategory_data"
                :key="item.categorySubCode"
                :value="item.categorySubCode"
              >
                {{ item.categorySubName }}
              </option>
            </select>
          </div>
          <div class="form-group d-flex align-items-center ml-auto">
            <input
              id="search_keyword"
              v-model="search.keyword"
              v-validate="'required'"
              type="text"
              name="關鍵字"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.關鍵字')}"
              placeholder="輸入關鍵字"
            >
          </div>
          <a
            href="#"
            class="search_btn d-flex align-items-center justify-content-center"
            @click.prevent="search_product()"
          >
            <span>搜尋</span>
          </a>
        </div>
        <ul
          v-if="tab_content === 'list'"
          class="nav nav-tabs"
        >
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': tab_content === 'list' }"
              @click.prevent="initial_product(), tab_content = 'list', product_content = '', product_show_section = 'basic', init_validate()"
            >商品列表</a>
          </li>
          <li class="nav-item">
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': tab_content === 'detail' && product_content === 'create' }"
              @click.prevent="tab_content = 'detail', product_content = 'create', product_show_section = 'basic', initial_product(), init_validate()"
            >建立商品</a>
          </li>
          <li
            v-if="product_content === 'edit'"
            class="nav-item"
          >
            <a
              href="#"
              class="nav-link"
              :class="{ 'active': tab_content === 'detail' && product_content === 'edit' }"
              @click.prevent="init_validate()"
            >編輯商品</a>
          </li>
        </ul>
        <div
          v-show="tab_content === 'list'"
          class="table-responsive"
        >
          <table
            v-show="product_list.length > 0"
            class="product_table table table-borderless"
          >
            <thead class="thead-light">
              <tr>
                <th scope="col">
                  商品ID
                </th>
                <th scope="col">
                  商品名稱
                </th>
                <th scope="col">
                  規格
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  售價
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  前台顯示
                </th>
                <th
                  scope="col"
                  class="text-center"
                >
                  是否缺貨
                </th>
              </tr>
            </thead>
            <draggable
              v-model="product_list"
              tag="tbody"
              @change="update_product_order"
            >
              <tr
                v-for="(product, index) in product_list"
                :key="product.prodSerial"
              >
                <td>
                  <a
                    href="#"
                    class="text-color_primary"
                    @click.prevent="get_product_detail(product.prodSerial)"
                  >{{ product.prodSerial }}</a>
                </td>
                <td>
                  <a
                    href="#"
                    class="text-color_primary"
                    @click.prevent="get_product_detail(product.prodSerial)"
                  >{{ product.prodName }}</a>
                </td>
                <td>{{ product.standard }}</td>
                <td class="text-center">
                  {{ product.price }}
                </td>
                <td class="text-center">
                  <label
                    :for="`optionShowFront_switch_btn_${index}`"
                    class="switch_btn"
                    :class="{ 'switch_checked': product.optionShowFront }"
                    @click.prevent="update_product_optionshow(product.prodSerial, 'showFront', product.optionShowFront)"
                  >
                    <input
                      :id="`optionShowFront_switch_btn_${index}`"
                      type="checkbox"
                    >
                  </label>
                </td>
                <td class="text-center">
                  <label
                    :for="`optionOutOfStock_switch_btn_${index}`"
                    class="switch_btn"
                    :class="{ 'switch_checked': product.optionOutOfStock }"
                    @click.prevent="update_product_optionshow(product.prodSerial, 'outOfStock', product.optionOutOfStock)"
                  >
                    <input
                      :id="`optionOutOfStock_switch_btn_${index}`"
                      type="checkbox"
                    >
                  </label>
                </td>
              </tr>
            </draggable>
          </table>
        <!-- <ul v-if="filter_Data.length > 0" class="pagination">
          <li class="pagination-item" :class="{ 'pagination-item-disabled': !pagination.has_pre }">
            <a
              href="#"
              class="pagination-link"
              aria-label="Previous"
              @click.prevent="change_page(pagination.current_page - 1)"
            >
              <i class="icon-chevron-thin-left"></i>
            </a>
          </li>
          <li v-for="(page, index) in pagination.total_pages" :key="index" class="pagination-item">
            <a
              href="#"
              class="pagination-link"
              :class="{ 'pagination-link-active': pagination.current_page === page }"
              @click.prevent="change_page(page)"
            >{{ page }}</a>
          </li>
          <li class="pagination-item" :class="{ 'pagination-item-disabled': !pagination.has_next }">
            <a
              href="#"
              class="pagination-link"
              aria-label="Next"
              @click.prevent="change_page(pagination.current_page + 1)"
            >
              <i class="icon-chevron-thin-right"></i>
            </a>
          </li>
        </ul> -->
        </div>
        <div
          v-show="tab_content === 'detail'"
          class="product_section"
        >
          <div class="d-flex justify-content-end">
            <a
              href="#"
              class="btn btn_outline_primary px-2"
              @click.prevent="tab_content = 'list',product_content = '', init_validate(), initial_product()"
            >返回</a>
          </div>
          <ul
            class="nav nav-tabs mb-3"
          >
            <li
              v-for="section in product_show_section_list"
              :key="section.id"
              class="nav-item"
            >
              <a
                href="#"
                class="nav-link"
                :class="{ 'active': product_show_section === section.id }"
                @click.prevent="product_show_section = section.id"
              >{{ section.name }}</a>
            </li>
          </ul>
          <div
            v-show="product_show_section === 'basic'"
            class="container-fluid"
          >
            <div class="row">
              <div
                v-if="temp_search.length !== 0 && temp_category_data.length === temp_search.length"
                class="col-12 mb-3"
              >
                <div
                  v-for="(item, index) in temp_category_data"
                  :key="index"
                  class="search_section d-flex align-items-center"
                >
                  <div class="form-group">
                    <label :for="`temp_search_system_${index + 1}`">系統</label>
                    <select
                      :id="`temp_search_system_${index + 1}`"
                      v-model="temp_search[index].system"
                      v-validate="'required'"
                      :name="`系統 ${index + 1}`"
                      class="form-control search_brand"
                      :class="{'is-invalid': errors.has(`detail.系統 ${index + 1}`)}"
                      data-vv-scope="detail"
                      @change="get_temp_main_category($event.target.value, index)"
                    >
                      <option
                        value
                        disabled
                        selected
                        hidden
                      >
                        系統
                      </option>
                      <option
                        v-for="system in system_data"
                        :key="system.systemCode"
                        :value="system.systemCode"
                      >
                        {{ system.systemName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label :for="`temp_search_categoryCode_${index + 1}`">主分類</label>
                    <select
                      :id="`temp_search_categoryCode_${index + 1}`"
                      v-model="temp_search[index].categoryCode"
                      v-validate="'required'"
                      :name="`主分類 ${index + 1}`"
                      class="form-control"
                      :class="{'is-invalid': errors.has(`detail.主分類 ${index + 1}`)}"
                      data-vv-scope="detail"
                      @change="get_temp_sub_category($event.target.value, index)"
                    >
                      <option
                        value
                        disabled
                        selected
                        hidden
                      >
                        主分類
                      </option>
                      <option
                        v-for="category in item.main_category"
                        :key="category.categoryCode"
                        :value="category.categoryCode"
                      >
                        {{ category.categoryName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label :for="`temp_search_categorySubCode_${index + 1}`">次分類</label>
                    <select
                      :id="`temp_search_categorySubCode_${index + 1}`"
                      v-model="temp_search[index].categorySubCode"
                      v-validate="'required'"
                      :name="`次分類 ${index + 1}`"
                      class="form-control"
                      :class="{'is-invalid': errors.has(`detail.次分類 ${index + 1}`)}"
                      data-vv-scope="detail"
                    >
                      <option
                        value
                        disabled
                        selected
                        hidden
                      >
                        次分類
                      </option>
                      <option
                        v-for="category in item.sub_category"
                        :key="category.categorySubCode"
                        :value="category.categorySubCode"
                      >
                        {{ category.categorySubName }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group m-0">
                    <div class="form-check form-check-inline m-0">
                      <input
                        :id="`temp_search_optionPrimary_${index + 1}`"
                        v-model="temp_search[index].optionPrimary"
                        class="form-check-input"
                        type="checkbox"
                        :name="`temp_search_optionPrimary_${index + 1}`"
                        :true-value="true"
                        :false-value="false"
                      >
                      <label
                        class="form-check-label"
                        :for="`temp_search_optionPrimary_${index + 1}`"
                      >主分類</label>
                    </div>
                  </div>
                  <a
                    v-if="temp_category_data.length > 1"
                    href="#"
                    class="btn btn_outline_danger px-2 rounded"
                    @click.prevent="delete_product_category(index)"
                  >
                    刪除
                  </a>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <a
                    href="#"
                    class="d-flex btn btn_primary px-2 m-0"
                    @click.prevent="add_product_category()"
                  >
                    新增歸屬
                  </a>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-xl-4">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    <span>狀態相關</span>
                  </div>
                  <div class="product_section_item_body">
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_prodSerial">商品編號</label>
                      <input
                        id="product_detail_prodSerial"
                        v-model.trim="product_detail.prodSerial"
                        v-validate="'required'"
                        type="text"
                        name="商品編號"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.商品編號')}"
                        data-vv-scope="detail"
                        placeholder="商品編號"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_prodNameErp">商品ERP</label>
                      <input
                        id="product_detail_prodNameErp"
                        v-model.trim="product_detail.prodNameErp"
                        v-validate="'required'"
                        type="text"
                        name="商品ERP"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.商品ERP')}"
                        data-vv-scope="detail"
                        placeholder="商品ERP"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_prodName">商品名稱</label>
                      <input
                        id="product_detail_prodName"
                        v-model.trim="product_detail.prodName"
                        v-validate="'required'"
                        type="text"
                        name="商品名稱"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.商品名稱')}"
                        data-vv-scope="detail"
                        placeholder="商品名稱"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_prodName2">專網名稱</label>
                      <input
                        id="product_detail_prodName2"
                        v-model.trim="product_detail.prodName2"
                        v-validate="'required'"
                        type="text"
                        name="專網名稱"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.專網名稱')}"
                        data-vv-scope="detail"
                        placeholder="專網商品名稱"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_standard">規格</label>
                      <input
                        id="product_detail_standard"
                        v-model.trim="product_detail.standard"
                        v-validate="'required'"
                        type="text"
                        name="規格"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.規格')}"
                        data-vv-scope="detail"
                        placeholder="規格"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_barCode">條碼</label>
                      <input
                        id="product_detail_barCode"
                        v-model.trim="product_detail.barCode"
                        type="text"
                        name="條碼"
                        class="form-control form_input"
                        placeholder="條碼"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_prodSerialChannel">通路品號</label>
                      <input
                        id="product_detail_prodSerialChannel"
                        v-model.trim="product_detail.prodSerialChannel"
                        type="text"
                        name="通路品號"
                        class="form-control form_input"
                        placeholder="通路品號"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_type">類型</label>
                      <select
                        id="product_detail_type"
                        v-model="product_detail.type"
                        v-validate="'required'"
                        name="類型"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.類型')}"
                        data-vv-scope="detail"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          類型
                        </option>
                        <option
                          v-for="item in option.prodType"
                          :key="item"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_unit">單位</label>
                      <input
                        id="product_detail_unit"
                        v-model.trim="product_detail.unit"
                        v-validate="'required'"
                        type="text"
                        name="單位"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.單位')}"
                        data-vv-scope="detail"
                        placeholder="單位"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_placeOfOrigin">產地</label>
                      <input
                        id="product_detail_placeOfOrigin"
                        v-model.trim="product_detail.placeOfOrigin"
                        type="text"
                        name="產地"
                        class="form-control form_input"
                        placeholder="產地"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_preservationCode">保存方式</label>
                      <select
                        id="product_detail_preservationCode"
                        v-model="product_detail.preservationCode"
                        v-validate="'required'"
                        name="保存方式"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.保存方式')}"
                        data-vv-scope="detail"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          保存方式
                        </option>
                        <option
                          v-for="item in option.preservation"
                          :key="item.preservationCode"
                          :value="item.preservationCode"
                        >
                          {{ item.preservationName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_preservationExpMonth">保存月份</label>
                      <input
                        id="product_detail_preservationExpMonth"
                        v-model.number="product_detail.preservationExpMonth"
                        v-validate="'required'"
                        type="number"
                        name="保存月份"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.保存月份')}"
                        data-vv-scope="detail"
                        placeholder="保存月份"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_dtPublic">上架日期</label>
                      <input
                        id="product_detail_dtPublic"
                        v-model="product_detail.dtPublic"
                        v-validate="'required'"
                        type="date"
                        name="上架日期"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.上架日期')}"
                        data-vv-scope="detail"
                        placeholder="上架日期"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_dtUnPublic">最後日期</label>
                      <input
                        id="product_detail_dtUnPublic"
                        v-model="product_detail.dtUnPublic"
                        v-validate="'required'"
                        type="date"
                        name="最後日期"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.最後日期')}"
                        data-vv-scope="detail"
                        placeholder="最後日期"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>啟用狀態</label>
                      <div class="form-check form-check-inline">
                        <input
                          id="status_true"
                          v-model="product_detail.status"
                          class="form-check-input"
                          type="radio"
                          name="status"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          for="status_true"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="status_false"
                          v-model="product_detail.status"
                          class="form-check-input"
                          type="radio"
                          name="status"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          for="status_false"
                        >否</label>
                      </div>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>是否缺貨</label>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionOutOfStock_true"
                          v-model="product_detail.optionOutOfStock"
                          class="form-check-input"
                          type="radio"
                          name="optionOutOfStock"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          for="optionOutOfStock_true"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionOutOfStock_false"
                          v-model="product_detail.optionOutOfStock"
                          class="form-check-input"
                          type="radio"
                          name="optionOutOfStock"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          for="optionOutOfStock_false"
                        >否</label>
                      </div>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>前台顯示</label>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionShowFront_true"
                          v-model="product_detail.optionShowFront"
                          class="form-check-input"
                          type="radio"
                          name="optionShowFront"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          for="optionShowFront_true"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionShowFront_false"
                          v-model="product_detail.optionShowFront"
                          class="form-check-input"
                          type="radio"
                          name="optionShowFront"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          for="optionShowFront_false"
                        >否</label>
                      </div>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>排除VIP與累積優惠</label>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionExcludeVipRank_true"
                          v-model="product_detail.optionExcludeVipRank"
                          class="form-check-input"
                          type="radio"
                          name="optionExcludeVipRank"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          for="optionExcludeVipRank_true"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionExcludeVipRank_false"
                          v-model="product_detail.optionExcludeVipRank"
                          class="form-check-input"
                          type="radio"
                          name="optionExcludeVipRank"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          for="optionExcludeVipRank_false"
                        >否</label>
                      </div>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>排除參加活動</label>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionExcludeSaleEvent_true"
                          v-model="product_detail.optionExcludeSaleEvent"
                          class="form-check-input"
                          type="radio"
                          name="optionExcludeSaleEvent"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          for="optionExcludeSaleEvent_true"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionExcludeSaleEvent_false"
                          v-model="product_detail.optionExcludeSaleEvent"
                          class="form-check-input"
                          type="radio"
                          name="optionExcludeSaleEvent"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          for="optionExcludeSaleEvent_false"
                        >否</label>
                      </div>
                    </div>
                    <div class="form-group d-flex align-items-baseline">
                      <label>排除時間</label>
                      <div>
                        <div class="d-flex align-items-center">
                          <div class="form-check form-check-inline">
                            <input
                              id="optionMonday"
                              v-model="product_detail.optionMonday"
                              class="form-check-input"
                              type="checkbox"
                              name="optionMonday"
                              :true-value="true"
                              :false-value="false"
                            >
                            <label
                              class="form-check-label"
                              for="optionMonday"
                            >一</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              id="optionTuesday"
                              v-model="product_detail.optionTuesday"
                              class="form-check-input"
                              type="checkbox"
                              name="optionTuesday"
                              :true-value="true"
                              :false-value="false"
                            >
                            <label
                              class="form-check-label"
                              for="optionTuesday"
                            >二</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              id="optionWednesday"
                              v-model="product_detail.optionWednesday"
                              class="form-check-input"
                              type="checkbox"
                              name="optionWednesday"
                              :true-value="true"
                              :false-value="false"
                            >
                            <label
                              class="form-check-label"
                              for="optionWednesday"
                            >三</label>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="form-check form-check-inline">
                            <input
                              id="optionThursday"
                              v-model="product_detail.optionThursday"
                              class="form-check-input"
                              type="checkbox"
                              name="optionThursday"
                              :true-value="true"
                              :false-value="false"
                            >
                            <label
                              class="form-check-label"
                              for="optionThursday"
                            >四</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              id="optionFriday"
                              v-model="product_detail.optionFriday"
                              class="form-check-input"
                              type="checkbox"
                              name="optionFriday"
                              :true-value="true"
                              :false-value="false"
                            >
                            <label
                              class="form-check-label"
                              for="optionFriday"
                            >五</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              id="optionSaturday"
                              v-model="product_detail.optionSaturday"
                              class="form-check-input"
                              type="checkbox"
                              name="optionSaturday"
                              :true-value="true"
                              :false-value="false"
                            >
                            <label
                              class="form-check-label"
                              for="optionSaturday"
                            >六</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="d-flex align-items-center mb-2">
                        <label
                          for="product_detail_excludePayment"
                        >排除付款</label>
                        <a
                          href="#"
                          class="btn btn_outline_primary px-2"
                          @click.prevent="add_exclude_payment()"
                        >
                          新增
                        </a>
                      </div>
                      <div
                        v-for="(payment, index) in excludePayment"
                        :key="index"
                        class="d-flex align-items-center mb-2"
                      >
                        <select
                          id="product_detail_excludePayment"
                          v-model="excludePayment[index].paymentCode"
                          v-validate="'required'"
                          :name="`排除付款 ${index + 1}`"
                          class="form-control form_input"
                          :class="{'is-invalid': errors.has(`detail.排除付款 ${index + 1}`)}"
                          data-vv-scope="detail"
                        >
                          <option
                            value
                            disabled
                            selected
                            hidden
                          >
                            排除付款
                          </option>
                          <option
                            v-for="item in nonpoint_payment"
                            :key="item.paymentCode"
                            :value="item.paymentCode"
                          >
                            {{ item.paymentName }}
                          </option>
                        </select>
                        <a
                          v-if="excludePayment.length > 0"
                          href="#"
                          class="btn btn_outline_danger px-2 ml-1"
                          @click.prevent="delete_exclude_payment(index)"
                        >
                          刪除
                        </a>
                      </div>
                    </div>
                    <div class="label_table mb-2">
                      <span class="d-block mb-2">商品標籤設定</span>
                      <div class="d-flex">
                        <div class="flex-fill">
                          <table class="table table-borderless">
                            <thead class="thead-light">
                              <tr class="border-0">
                                <th scope="col">
                                  次標籤
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="index in product_label_amount"
                                :key="index"
                                class="border-0"
                              >
                                <td>
                                  <div class="form-group d-flex align-items-center m-0">
                                    <select
                                      v-model="product_label_data[index - 1].labelSubCode"
                                      v-validate="'required'"
                                      :name="`次標籤 ${index}`"
                                      class="form-control form_input"
                                      :class="{'is-invalid': errors.has(`detail.次標籤 ${index}`)}"
                                      data-vv-scope="detail"
                                    >
                                      <option
                                        value
                                        disabled
                                        selected
                                        hidden
                                      >
                                        商品標籤
                                      </option>
                                      <option
                                        v-for="item in product_label"
                                        :key="item.labelSubCode"
                                        :value="item.labelSubCode"
                                      >
                                        {{ item.labelSubName }}
                                      </option>
                                    </select>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="d-flex justify-content-center align-items-center">
                            <a
                              href="#"
                              class="circle_btn"
                              @click.prevent="product_label_amount += 1"
                            >
                              <i class="icon-add" />
                            </a>
                            <a
                              v-if="product_label_amount > 1"
                              href="#"
                              class="circle_btn circle_btn_danger"
                              @click.prevent="product_label_amount === 1 ? '' : product_label_amount -= 1"
                            >
                              <i class="icon-minus" />
                            </a>
                          </div>
                        </div>
                        <div class="flex-fill">
                          <table class="table table-borderless">
                            <thead class="thead-light">
                              <tr class="border-0">
                                <th scope="col">
                                  分析標籤
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="index in analysis_label_amount"
                                :key="index"
                                class="border-0"
                              >
                                <td>
                                  <div class="form-group d-flex align-items-center m-0">
                                    <select
                                      v-model="analysis_label_data[index - 1].labelSubCode"
                                      v-validate="'required'"
                                      :name="`分析標籤 ${index}`"
                                      class="form-control form_input"
                                      :class="{'is-invalid': errors.has(`detail.分析標籤 ${index}`)}"
                                      data-vv-scope="detail"
                                    >
                                      <option
                                        value
                                        disabled
                                        selected
                                        hidden
                                      >
                                        分析標籤
                                      </option>
                                      <option
                                        v-for="item in analysis_label"
                                        :key="item.labelSubCode"
                                        :value="item.labelSubCode"
                                      >
                                        {{ item.labelSubName }}
                                      </option>
                                    </select>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="d-flex justify-content-center align-items-center">
                            <a
                              href="#"
                              class="circle_btn"
                              @click.prevent="analysis_label_amount += 1"
                            >
                              <i class="icon-add" />
                            </a>
                            <a
                              v-if="analysis_label_amount > 1"
                              href="#"
                              class="circle_btn circle_btn_danger"
                              @click.prevent="analysis_label_amount === 1 ? '' : analysis_label_amount -= 1"
                            >
                              <i class="icon-minus" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    <span>價金相關</span>
                  </div>
                  <div class="product_section_item_body">
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_optionTax">稅別</label>
                      <select
                        id="product_detail_optionTax"
                        v-model.number="product_detail.optionTax"
                        v-validate="'required'"
                        name="稅別"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.稅別')}"
                        data-vv-scope="detail"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          稅別
                        </option>
                        <option
                          v-for="item in option.optionTax"
                          :key="item.name"
                          :value="item.code"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_price">售價</label>
                      <input
                        id="product_detail_price"
                        v-model.number="product_detail.price"
                        v-validate="'required'"
                        type="number"
                        name="售價"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.售價')}"
                        data-vv-scope="detail"
                        placeholder="售價"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_pricePlus">加購價</label>
                      <input
                        id="product_detail_pricePlus"
                        v-model.number="product_detail.pricePlus"
                        v-validate="'required'"
                        type="number"
                        name="加購價"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.加購價')}"
                        data-vv-scope="detail"
                        placeholder="加購價"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    <span>運送/庫存/購買相關</span>
                  </div>
                  <div class="product_section_item_body">
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_transportType">運送類型</label>
                      <select
                        id="product_detail_transportType"
                        v-model="product_detail.transportType"
                        v-validate="'required'"
                        name="運送類型"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.運送類型')}"
                        data-vv-scope="detail"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          運送類型
                        </option>
                        <option
                          v-for="item in option.transportType"
                          :key="item"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_assignDepotCode">指定出貨倉</label>
                      <select
                        id="product_detail_assignDepotCode"
                        v-model="product_detail.assignDepotCode"
                        name="指定出貨倉"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.指定出貨倉')}"
                        data-vv-scope="detail"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          指定出貨倉
                        </option>
                        <option
                          :value="''"
                        >
                          不指定
                        </option>
                        <option
                          v-for="item in option.depot"
                          :key="item.depotCode"
                          :value="item.depotCode"
                        >
                          {{ item.depotCode }} {{ item.depotName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>計算庫存</label>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionStorageAdj_true"
                          v-model="product_detail.optionStorageAdj"
                          class="form-check-input"
                          type="radio"
                          name="optionStorageAdj"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          for="optionStorageAdj_true"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionStorageAdj_false"
                          v-model="product_detail.optionStorageAdj"
                          class="form-check-input"
                          type="radio"
                          name="optionStorageAdj"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          for="optionStorageAdj_false"
                        >否</label>
                      </div>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>同步庫存</label>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionStorageSync_true"
                          v-model="product_detail.optionStorageSync"
                          class="form-check-input"
                          type="radio"
                          name="optionStorageSync"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          for="optionStorageSync_true"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionStorageSync_false"
                          v-model="product_detail.optionStorageSync"
                          class="form-check-input"
                          type="radio"
                          name="optionStorageSync"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          for="optionStorageSync_false"
                        >否</label>
                      </div>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_storageLimit">前台限制數量</label>
                      <input
                        id="product_detail_storageLimit"
                        v-model.number="product_detail.storageLimit"
                        v-validate="'required'"
                        type="number"
                        name="前台限制數量"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.前台限制數量')}"
                        data-vv-scope="detail"
                        placeholder="前台限制數量"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_storageLimitBackend">後台限制數量</label>
                      <input
                        id="product_detail_storageLimitBackend"
                        v-model.number="product_detail.storageLimitBackend"
                        v-validate="'required'"
                        type="number"
                        name="後台限制數量"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.後台限制數量')}"
                        data-vv-scope="detail"
                        placeholder="後台限制數量"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_optionDeliveryTimes">最大配送數</label>
                      <input
                        id="product_detail_optionDeliveryTimes"
                        v-model.number="product_detail.optionDeliveryTimes"
                        v-validate="'required'"
                        type="number"
                        name="最大配送數"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.最大配送數')}"
                        data-vv-scope="detail"
                        placeholder="最大配送數"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_arriveStrategy">到貨日策略</label>
                      <select
                        id="product_detail_arriveStrategy"
                        v-model="product_detail.arriveStrategyCode"
                        v-validate="'required'"
                        name="到貨日策略"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.到貨日策略')}"
                        data-vv-scope="detail"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          到貨日策略
                        </option>
                        <option
                          v-for="item in option.arriveStrategy"
                          :key="item.arriveStrategyCode"
                          :value="item.arriveStrategyCode"
                        >
                          {{ item.arriveStrategyName }}
                        </option>
                      </select>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_textNonSchedule">未在檔期內訊息</label>
                      <input
                        id="product_detail_textNonSchedule"
                        v-model="product_detail.textNonSchedule"
                        type="text"
                        name="未在檔期內訊息"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.未在檔期內訊息')}"
                        data-vv-scope="detail"
                        placeholder="未在檔期內訊息"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_ediFuguiCode">福貴糕EDI代碼</label>
                      <input
                        id="product_detail_ediFuguiCode"
                        v-model="product_detail.ediFuguiCode"
                        type="text"
                        name="福貴糕EDI代碼"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.福貴糕EDI代碼')}"
                        data-vv-scope="detail"
                        placeholder="福貴糕EDI代碼"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div />
          </div>
          <div
            v-show="product_show_section === 'assist'"
            class="container-fluid"
          >
            <div class="row">
              <div class="col-12">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    SEO 設定
                  </div>
                  <div class="product_section_item_body">
                    <div class="form-group">
                      <label
                        for="product_detail_htmlTitle"
                        class="text-left mb-2"
                      >
                        網頁標題
                        <span class="d-inline-block ml-2 text-danger">(最多 30 個中文字)</span>
                      </label>
                      <input
                        id="product_detail_htmlTitle"
                        v-model.trim="product_detail.htmlTitle"
                        v-validate="'required'"
                        type="text"
                        name="網頁標題"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.網頁標題')}"
                        data-vv-scope="detail"
                        placeholder="網頁標題"
                      >
                    </div>
                    <div class="form-group">
                      <label
                        for="product_detail_htmlKeywords"
                        class="text-left mb-2"
                      >
                        網頁關鍵字
                        <span class="d-inline-block ml-2 text-danger">(最多 50 個中文字，頓號區隔)</span>
                      </label>
                      <input
                        id="product_detail_htmlKeywords"
                        v-model="product_detail.htmlKeywords"
                        v-validate="'required'"
                        type="text"
                        name="網頁關鍵字"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.網頁關鍵字')}"
                        data-vv-scope="detail"
                        placeholder="網頁關鍵字1、網頁關鍵字2、網頁關鍵字3"
                      >
                    </div>
                    <div class="form-group">
                      <label class="text-left mb-2">
                        網頁描述
                        <span class="d-inline-block ml-2 text-danger">(最多 250 個中文字)</span>
                      </label>
                      <input
                        id="product_detail_htmlDesc"
                        v-model="product_detail.htmlDesc"
                        v-validate="'required'"
                        type="text"
                        name="網頁描述"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.網頁描述')}"
                        data-vv-scope="detail"
                        placeholder="網頁描述"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    組合相關
                  </div>
                  <div class="product_section_item_body">
                    <div class="form-group d-flex align-items-center">
                      <label>組合商品</label>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionPackage_true"
                          v-model="product_detail.optionPackage"
                          class="form-check-input"
                          type="radio"
                          name="optionPackage"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          for="optionPackage_true"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionPackage_false"
                          v-model="product_detail.optionPackage"
                          class="form-check-input"
                          type="radio"
                          name="optionPackage"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          for="optionPackage_false"
                        >否</label>
                      </div>
                    </div>
                    <div
                      v-if="product_detail.optionPackage"
                      class="bom_table mb-2"
                    >
                      <div class="d-flex align-items-center mb-2">
                        <label>組合商品清單</label>
                        <a
                          href="#"
                          class="btn btn_outline_primary px-2"
                          @click.prevent="add_bom()"
                        >
                          新增組合
                        </a>
                      </div>
                      <table class="table table-borderless">
                        <thead class="thead-light">
                          <tr class="border-0">
                            <th scope="col">
                              商品代碼
                            </th>
                            <th scope="col">
                              數量
                            </th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(bom, index) in product_detail.bom"
                            :key="index"
                            class="border-0"
                          >
                            <td>
                              <div class="form-group d-flex align-items-center m-0">
                                <input
                                  v-model.trim="product_detail.bom[index].prodItemSerial"
                                  v-validate="'required'"
                                  type="text"
                                  :name="`組合商品 ${index + 1}`"
                                  class="form-control form_input"
                                  :class="{'is-invalid': errors.has(`detail.組合商品 ${index + 1}`)}"
                                  data-vv-scope="detail"
                                  placeholder="輸入商品代碼"
                                >
                              </div>
                            </td>
                            <td>
                              <div class="form-group d-flex align-items-center m-0">
                                <input
                                  v-model.number="product_detail.bom[index].prodQunatity"
                                  v-validate="'required'"
                                  type="text"
                                  :name="`組合商品數量 ${index + 1}`"
                                  class="form-control form_input"
                                  :class="{'is-invalid': errors.has(`detail.組合商品數量 ${index + 1}`)}"
                                  data-vv-scope="detail"
                                  placeholder="請輸入數量"
                                >
                              </div>
                            </td>
                            <td>
                              <a
                                v-if="product_detail.bom.length > 1"
                                href="#"
                                class="circle_btn circle_btn_danger"
                                @click.prevent="delete_bom(index)"
                              >
                                <i class="icon-minus" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    預購相關(限預購類型)
                  </div>
                  <div class="product_section_item_body">
                    <div
                      v-if="product_detail.type === '預購' && product_detail.preOrder.length > 0"
                      class="product_section_item"
                    >
                      <div
                        v-for="(preOrder, index) in product_detail.preOrder"
                        :key="index"
                      >
                        <div class="d-flex align-items-center mb-2">
                          <label>時段 {{ index + 1 }}.</label>
                          <a
                            v-if="product_detail.preOrder.length > 1"
                            href="#"
                            class="btn btn_outline_danger px-2 ml-1"
                            @click.prevent="delete_pre_order(index)"
                          >
                            刪除
                          </a>
                        </div>
                        <div class="form-group d-flex align-items-center">
                          <label :for="`product_detail_dtStart_${index + 1}`">預購日期</label>
                          <input
                            :id="`product_detail_dtStart_${index + 1}`"
                            v-model="product_detail.preOrder[index].dtStart"
                            v-validate="'required'"
                            type="date"
                            :name="`預購日期 ${index + 1}`"
                            class="form-control form_input"
                            :class="{'is-invalid': errors.has(`detail.預購日期 ${index + 1}`)}"
                            data-vv-scope="detail"
                            placeholder="預購日期"
                          >
                        </div>
                        <div class="form-group d-flex align-items-center">
                          <label :for="`product_detail_dtEnd_${index + 1}`">結束日期</label>
                          <input
                            :id="`product_detail_dtEnd_${index + 1}`"
                            v-model="product_detail.preOrder[index].dtEnd"
                            v-validate="'required'"
                            type="date"
                            :name="`結束日期 ${index + 1}`"
                            class="form-control form_input"
                            :class="{'is-invalid': errors.has(`detail.結束日期 ${index + 1}`)}"
                            data-vv-scope="detail"
                            placeholder="結束日期"
                          >
                        </div>
                        <div class="form-group d-flex align-items-center">
                          <label :for="`product_detail_dtArrive_${index + 1}`">最快到貨日</label>
                          <input
                            :id="`product_detail_dtArrive_${index + 1}`"
                            v-model="product_detail.preOrder[index].dtArrive"
                            v-validate="'required'"
                            type="date"
                            :name="`最快到貨日 ${index + 1}`"
                            class="form-control form_input"
                            :class="{'is-invalid': errors.has(`detail.最快到貨日 ${index + 1}`)}"
                            data-vv-scope="detail"
                            placeholder="最快到貨日"
                          >
                        </div>
                      </div>
                      <div class="d-flex justify-content-center align-items-center mb-2">
                        <a
                          href="#"
                          class="btn btn_outline_primary px-2 flex-fill"
                          @click.prevent="add_pre_order()"
                        >
                          新增時段
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl-4">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    <span>成分相關</span>
                  </div>
                  <div class="product_section_item_body">
                    <div class="form-group d-flex align-items-center">
                      <label>成分顯示</label>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionComponent_true"
                          v-model="product_detail.optionComponent"
                          class="form-check-input"
                          type="radio"
                          name="optionComponent"
                          :value="true"
                        >
                        <label
                          class="form-check-label"
                          for="optionComponent_true"
                        >是</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          id="optionComponent_false"
                          v-model="product_detail.optionComponent"
                          class="form-check-input"
                          type="radio"
                          name="optionComponent"
                          :value="false"
                        >
                        <label
                          class="form-check-label"
                          for="optionComponent_false"
                        >否</label>
                      </div>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_calorie">熱量</label>
                      <input
                        id="product_detail_calorie"
                        v-model.number="product_detail.component.calorie"
                        v-validate="'required'"
                        type="number"
                        name="熱量"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.熱量')}"
                        data-vv-scope="detail"
                        placeholder="熱量"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_protein">蛋白質</label>
                      <input
                        id="product_detail_protein"
                        v-model.number="product_detail.component.protein"
                        v-validate="'required'"
                        type="number"
                        name="蛋白質"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.蛋白質')}"
                        data-vv-scope="detail"
                        placeholder="蛋白質"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_fat">脂肪</label>
                      <input
                        id="product_detail_fat"
                        v-model.number="product_detail.component.fat"
                        v-validate="'required'"
                        type="number"
                        name="脂肪"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.脂肪')}"
                        data-vv-scope="detail"
                        placeholder="脂肪"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_transFat">反式脂肪</label>
                      <input
                        id="product_detail_transFat"
                        v-model.number="product_detail.component.transFat"
                        v-validate="'required'"
                        type="number"
                        name="反式脂肪"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.反式脂肪')}"
                        data-vv-scope="detail"
                        placeholder="反式脂肪"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_saturatedFat">飽和脂肪</label>
                      <input
                        id="product_detail_saturatedFat"
                        v-model.number="product_detail.component.saturatedFat"
                        v-validate="'required'"
                        type="number"
                        name="飽和脂肪"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.飽和脂肪')}"
                        data-vv-scope="detail"
                        placeholder="飽和脂肪"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_carbohydrate">碳水化合物</label>
                      <input
                        id="product_detail_carbohydrate"
                        v-model.number="product_detail.component.carbohydrate"
                        v-validate="'required'"
                        type="number"
                        name="碳水化合物"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.碳水化合物')}"
                        data-vv-scope="detail"
                        placeholder="碳水化合物"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_sugar">糖</label>
                      <input
                        id="product_detail_sugar"
                        v-model.number="product_detail.component.sugar"
                        v-validate="'required'"
                        type="number"
                        name="糖"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.糖')}"
                        data-vv-scope="detail"
                        placeholder="糖"
                      >
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label for="product_detail_chemistryNa">鈉</label>
                      <input
                        id="product_detail_chemistryNa"
                        v-model.number="product_detail.component.chemistryNa"
                        v-validate="'required'"
                        type="number"
                        name="鈉"
                        min="0"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('detail.鈉')}"
                        data-vv-scope="detail"
                        placeholder="鈉"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    配送設定(限食譜、計畫類型)
                  </div>
                  <div class="product_section_item_body">
                    <div
                      v-if="product_detail.type === '食譜' || product_detail.type === '計畫'"
                    >
                      <div class="d-flex align-items-center mb-2">
                        <label>搭配配送商品</label>
                        <a
                          href="#"
                          class="btn btn_outline_primary px-2"
                          @click.prevent="add_delivery()"
                        >
                          新增
                        </a>
                      </div>
                      <table class="table table-borderless">
                        <thead class="thead-light">
                          <tr class="border-0">
                            <th scope="col">
                              產品代碼
                            </th>
                            <th scope="col">
                              每X日配送
                            </th>
                            <th scope="col">
                              配送次數
                            </th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(delivery, index) in product_detail.delivery"
                            :key="index"
                            class="border-0"
                          >
                            <td>
                              <div class="form-group d-flex align-items-center m-0">
                                <input
                                  v-model.trim="product_detail.delivery[index].optionProdSerial"
                                  v-validate="'required'"
                                  type="text"
                                  :name="`配送商品代碼 ${index + 1}`"
                                  class="form-control form_input"
                                  :class="{'is-invalid': errors.has(`detail.配送商品代碼 ${index + 1}`)}"
                                  data-vv-scope="detail"
                                  placeholder="商品代碼"
                                >
                              </div>
                            </td>
                            <td>
                              <div class="form-group d-flex align-items-center m-0">
                                <input
                                  v-model.number="product_detail.delivery[index].optionDays"
                                  v-validate="'required'"
                                  type="number"
                                  :name="`配送商品週期 ${index + 1}`"
                                  class="form-control form_input"
                                  :class="{'is-invalid': errors.has(`detail.配送商品週期 ${index + 1}`)}"
                                  data-vv-scope="detail"
                                  placeholder="配送週期"
                                >
                              </div>
                            </td>
                            <td>
                              <div class="form-group d-flex align-items-center m-0">
                                <input
                                  v-model.number="product_detail.delivery[index].optionTimes"
                                  v-validate="'required'"
                                  type="number"
                                  :name="`配送商品次數 ${index + 1}`"
                                  class="form-control form_input"
                                  :class="{'is-invalid': errors.has(`detail.配送商品次數 ${index + 1}`)}"
                                  data-vv-scope="detail"
                                  placeholder="配送次數"
                                >
                              </div>
                            </td>
                            <td>
                              <a
                                v-if="product_detail.delivery.length > 0"
                                href="#"
                                class="circle_btn circle_btn_danger"
                                @click.prevent="delete_delivery(index)"
                              >
                                <i class="icon-minus" />
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    食譜相關(限食譜類型)
                  </div>
                  <div class="product_section_item_body">
                    <div
                      v-if="product_detail.type === '食譜'"
                      class="container-fluid"
                    >
                      <div class="row">
                        <div class="col-12 col-xl-6">
                          <div class="d-flex align-items-center mb-2">
                            <label>食譜做法</label>
                            <a
                              href="#"
                              class="btn btn_outline_primary px-2"
                              @click.prevent="add_recipe_cooking()"
                            >
                              新增
                            </a>
                          </div>
                          <div
                            v-for="(recipeCooking, index) in product_detail.recipeCooking"
                            :key="index"
                            class="d-flex mb-2"
                          >
                            <div class="form-group d-flex align-items-center flex-fill my-1">
                              <label :for="`product_detail_cookingDesc_${index + 1}`">順序{{ index + 1 }}</label>
                              <input
                                :id="`product_detail_cookingDesc_${index + 1}`"
                                v-model="product_detail.recipeCooking[index].cookingDesc"
                                v-validate="'required'"
                                type="text"
                                :name="`食譜作法順序 ${index + 1}`"
                                class="form-control form_input"
                                :class="{'is-invalid': errors.has(`detail.食譜作法順序 ${index + 1}`)}"
                                data-vv-scope="detail"
                                :placeholder="`順序${index + 1}`"
                              >
                              <a
                                v-if="product_detail.recipeCooking.length > 1"
                                href="#"
                                class="btn btn_outline_danger px-2 ml-1"
                                @click.prevent="delete_recipe_cooking(index)"
                              >
                                刪除
                              </a>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-xl-6">
                          <div class="d-flex align-items-center mb-2">
                            <label>食材清單</label>
                            <a
                              href="#"
                              class="btn btn_outline_primary px-2"
                              @click.prevent="add_recipe_goods()"
                            >
                              新增
                            </a>
                          </div>
                          <div
                            v-for="(recipeGoods, index) in product_detail.recipeGoods"
                            :key="index"
                            class="d-flex align-items-center mb-2"
                          >
                            <div class="flex-fill">
                              <div class="form-group d-flex align-items-center my-1">
                                <label :for="`product_detail_prodSerialTarget_${index + 1}`">對應商品</label>
                                <input
                                  :id="`product_detail_prodSerialTarget_${index + 1}`"
                                  v-model="product_detail.recipeGoods[index].prodSerialTarget"
                                  v-validate="'required'"
                                  type="text"
                                  :name="`食材清單 ${index + 1}`"
                                  class="form-control form_input"
                                  :class="{'is-invalid': errors.has(`detail.食材清單 ${index + 1}`)}"
                                  data-vv-scope="detail"
                                  placeholder="對應商品"
                                >
                              </div>
                              <div class="form-group d-flex align-items-center my-1">
                                <label :for="`product_detail_goodsName_${index + 1}`">食材名稱</label>
                                <input
                                  :id="`product_detail_goodsName_${index + 1}`"
                                  v-model="product_detail.recipeGoods[index].goodsName"
                                  v-validate="'required'"
                                  type="text"
                                  :name="`食材名稱 ${index + 1}`"
                                  class="form-control form_input"
                                  :class="{'is-invalid': errors.has(`detail.食材名稱 ${index + 1}`)}"
                                  data-vv-scope="detail"
                                  placeholder="食材名稱"
                                >
                              </div>
                              <div class="form-group d-flex align-items-center my-1">
                                <label :for="`product_detail_goodsPortions_${index + 1}`">食材份量</label>
                                <input
                                  :id="`product_detail_goodsPortions_${index + 1}`"
                                  v-model="product_detail.recipeGoods[index].goodsPortions"
                                  v-validate="'required'"
                                  type="text"
                                  :name="`食材份量 ${index + 1}`"
                                  class="form-control form_input"
                                  :class="{'is-invalid': errors.has(`detail.食材份量 ${index + 1}`)}"
                                  data-vv-scope="detail"
                                  placeholder="食材份量"
                                >
                              </div>
                            </div>
                            <a
                              v-if="product_detail.recipeGoods.length > 1"
                              href="#"
                              class="btn btn_outline_danger px-2 ml-1"
                              @click.prevent="delete_recipe_goods(index)"
                            >
                              刪除
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <overlay-scrollbars
            v-show="product_show_section === 'copywriting_mode_gping'"
            class="container-fluid"
            :options="{ scrollbars: { autoHide: 'scroll' } }"
          >
            <div class="row">
              <div class="col-12">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    商品文案資訊
                  </div>
                  <div class="product_section_item_body">
                    <div class="form-group">
                      <label class="text-left mb-2">商品介紹</label>
                      <ckeditor
                        v-model="product_detail.introduction"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                    <div class="form-group">
                      <label class="text-left mb-2">商品特色</label>
                      <ckeditor
                        v-model="product_detail.feature"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                    <div class="form-group">
                      <label class="text-left mb-2">規格說明</label>
                      <ckeditor
                        v-model="product_detail.specification"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                    <div class="form-group">
                      <label class="text-left mb-2">注意事項</label>
                      <ckeditor
                        v-model="product_detail.attention"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </overlay-scrollbars>
          <overlay-scrollbars
            v-show="product_show_section === 'copywriting_mode_wuer'"
            class="container-fluid"
            :options="{ scrollbars: { autoHide: 'scroll' } }"
          >
            <div class="row">
              <div class="col-12">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    商品文案資訊
                  </div>
                  <div class="product_section_item_body">
                    <div class="form-group">
                      <label class="text-left mb-2">商品介紹</label>
                      <ckeditor
                        v-model="product_detail.introduction2"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                    <div class="form-group">
                      <label class="text-left mb-2">商品特色</label>
                      <ckeditor
                        v-model="product_detail.feature2"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                    <div class="form-group">
                      <label class="text-left mb-2">規格說明</label>
                      <ckeditor
                        v-model="product_detail.specification2"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                    <div class="form-group">
                      <label class="text-left mb-2">注意事項</label>
                      <ckeditor
                        v-model="product_detail.attention2"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                    <div class="form-group">
                      <label class="text-left mb-2">安心認證</label>
                      <ckeditor
                        v-model="product_detail.certification"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                    <div class="form-group">
                      <label class="text-left mb-2">QA</label>
                      <ckeditor
                        v-model="product_detail.qa"
                        :editor="editor"
                        :config="editorConfig"
                        @ready="upload_article_image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </overlay-scrollbars>
          <div
            v-show="product_show_section === 'image'"
            class="container-fluid"
          >
            <div class="row">
              <div class="col-12">
                <div class="product_section_item">
                  <div class="product_section_item_header text-white rounded-top">
                    上傳/更新圖片(只要是相簿圖，jpg、jpeg、png、webp，動態gif也可以，但注意務必將圖片自行縮成430x430，後台不再做縮圖)
                  </div>
                  <div class="product_section_item_body">
                    <div
                      v-if="tab_content === 'detail' && product_content === 'edit'"
                      class="container-fluid"
                    >
                      <div class="d-flex mb-2">
                        <p>商品相簿(更新順位請單獨輸入要插入的排序數字即可)</p>
                        <a
                          href="#"
                          class="btn btn_outline_primary px-2 ml-1"
                          @click.prevent="add_image_album()"
                        >
                          新增
                        </a>
                      </div>
                      <div
                        v-if="product_images.imageAlbum.length !== 0"
                        class="row product_images"
                      >
                        <div
                          v-for="(item, index) in product_images.imageAlbum"
                          :key="index"
                          class="col form-group form_imageAlbum"
                        >
                          <div
                            class="form_input_imageAlbum"
                          >
                            <div class="input_custom">
                              <span>加入圖片</span>
                            </div>
                            <input
                              ref="files"
                              type="file"
                              :name="product_images.imageAlbum[index].filename"
                              :data-name="product_images.imageAlbum[index].filename"
                              class="form-control"
                              accept="image/*"
                              @change="readURL($event,$event.target)"
                            >
                            <div class="display_image">
                              <img
                                :id="product_images.imageAlbum[index].filename"
                                :src="item.url === '' ? `${process.env.VUE_APP_API_PATH}/images/imageAlbum.jpg` : item.url"
                                class="img-fluid"
                              >
                            </div>
                          </div>
                          <div class="d-flex flex-column">
                            <p class="text-center">
                              {{ index+1 }}
                            </p>
                            <div class="form-group">
                              <input
                                v-model="product_images.imageAlbum[index].newfilename"
                                type="text"
                                name=""
                                class="form-control form_input"
                                placeholder="更新順位"
                              >
                            </div>
                            <div class="d-flex justify-content-center">
                              <a
                                v-if="item.url !== ''"
                                href="#"
                                class="btn btn_outline_primary px-2 mx-2"
                                @click.prevent="update_image(item)"
                              >排序</a>
                              <a
                                href="#"
                                class="btn btn_outline_danger px-2 mx-2"
                                @click.prevent="delete_image(item, index)"
                              >刪除</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="tab_content === 'detail' && product_content === 'edit'"
                      class="container-fluid"
                    >
                      <div class="d-flex mb-2">
                        <p>商品相簿(保健用，更新順位請單獨輸入要插入的排序數字即可)</p>
                        <a
                          href="#"
                          class="btn btn_outline_primary px-2 ml-1"
                          @click.prevent="add_image_album_gh()"
                        >
                          新增
                        </a>
                      </div>
                      <div
                        v-if="product_images.imageAlbum_GH.length !== 0"
                        class="row product_images"
                      >
                        <div
                          v-for="(item, index) in product_images.imageAlbum_GH"
                          :key="index"
                          class="col form-group form_imageAlbum"
                        >
                          <div
                            class="form_input_imageAlbumGH"
                          >
                            <div class="input_custom">
                              <span>加入圖片</span>
                            </div>
                            <input
                              ref="files"
                              type="file"
                              :name="product_images.imageAlbum_GH[index].filename"
                              :data-name="product_images.imageAlbum_GH[index].filename"
                              class="form-control"
                              accept="image/*"
                              @change="readURL($event,$event.target)"
                            >
                            <div class="display_image">
                              <img
                                :id="product_images.imageAlbum_GH[index].filename"
                                :src="item.url === '' ? `${process.env.VUE_APP_API_PATH}/images/imageAlbum_GH.webp` : item.url"
                                class="img-fluid"
                              >
                            </div>
                          </div>
                          <div class="d-flex flex-column">
                            <p class="text-center">
                              {{ index+1 }}
                            </p>
                            <div class="form-group">
                              <input
                                v-model="product_images.imageAlbum_GH[index].newfilename"
                                type="text"
                                name=""
                                class="form-control form_input"
                                placeholder="更新順位"
                              >
                            </div>
                            <div class="d-flex justify-content-center">
                              <a
                                v-if="item.url !== ''"
                                href="#"
                                class="btn btn_outline_primary px-2 mx-2"
                                @click.prevent="update_image(item)"
                              >排序</a>
                              <a
                                href="#"
                                class="btn btn_outline_danger px-2 mx-2"
                                @click.prevent="delete_image(item, index)"
                              >刪除</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="tab_content === 'detail'"
                      class="container-fluid"
                    >
                      <div class="d-flex mb-2">
                        <p>商品圖片，只有吉品限定jpg且自動縮圖，其他站請自行做各自規格，請注意圖片在後台會看到快取導致圖片沒有變化，實際是已經上傳的，前台可以立即看到新圖更新</p>
                      </div>
                      <div class="product_images">
                        <div class="form-group form_input_image">
                          <label>無二專屬(480x480.png)</label>
                          <div class="input_custom">
                            <span>加入圖片</span>
                          </div>
                          <input
                            ref="files"
                            type="file"
                            data-name="image480x480"
                            class="form-control"
                            accept="image/x-png"
                            @change="readURL($event,$event.target)"
                          >
                          <div class="display_image">
                            <img
                              id="image480x480"
                              :src="product_images.image480x480"
                              class="img-fluid"
                            >
                          </div>
                        </div>
                        <div class="form-group form_input_image">
                          <label>保健用圖片(430x430)</label>
                          <div class="input_custom">
                            <span>加入圖片</span>
                          </div>
                          <input
                            ref="files"
                            type="file"
                            data-name="image430x430_GH"
                            class="form-control"
                            accept="image/*"
                            @change="readURL($event,$event.target)"
                          >
                          <div class="display_image">
                            <img
                              id="image430x430_GH"
                              :src="product_images.image430x430_GH"
                              class="img-fluid"
                            >
                          </div>
                        </div>
                        <div class="form-group form_input_image">
                          <label>福貴糕用圖片(430x430)</label>
                          <div class="input_custom">
                            <span>加入圖片</span>
                          </div>
                          <input
                            ref="files"
                            type="file"
                            data-name="image430x430_JH"
                            class="form-control"
                            accept="image/*"
                            @change="readURL($event,$event.target)"
                          >
                          <div class="display_image">
                            <img
                              id="image430x430_JH"
                              :src="product_images.image430x430_JH"
                              class="img-fluid"
                            >
                          </div>
                        </div>
                        <div class="form-group form_input_image">
                          <label>共用圖片(640x640.jpg)</label>
                          <div class="input_custom">
                            <span>加入圖片</span>
                          </div>
                          <input
                            ref="files"
                            type="file"
                            data-name="image640x640"
                            class="form-control"
                            accept="image/jpeg"
                            @change="readURL($event,$event.target)"
                          >
                          <div class="display_image">
                            <img
                              id="image640x640"
                              :src="product_images.image640x640"
                              class="img-fluid"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="product_show_section === 'recommend' && product_content === 'edit'"
            class="container-fluid"
          >
            <div class="row">
              <div
                v-for="(recommend, recommendIndex) in temp_recommend_data"
                :key="recommend.code"
                class="col-12 col-xl-4"
              >
                <div class="product_section_item">
                  <div class="product_section_item_header d-flex justify-content-between text-white rounded-top">
                    <span>{{ recommend.name }}推薦商品</span>
                    <a
                      href="#"
                      class="circle_btn"
                      @click.prevent="toggle_modal(recommendIndex)"
                    >
                      <i class="icon-add" />
                    </a>
                  </div>
                </div>
                <div class="product_section_item_body">
                  <div class="form-group d-flex align-items-center">
                    <div class="form-check form-check-inline">
                      <input
                        :id="`temp_recommend_modeNull_${recommendIndex + 1}`"
                        v-model="temp_recommend_data[recommendIndex].mode"
                        class="form-check-input"
                        type="radio"
                        :name="`temp_recommend_mode_${recommendIndex + 1}`"
                        :value="'none'"
                      >
                      <label
                        class="form-check-label"
                        :for="`temp_recommend_modeNull_${recommendIndex + 1}`"
                      >無</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        :id="`temp_recommend_modeAuto_${recommendIndex + 1}`"
                        v-model="temp_recommend_data[recommendIndex].mode"
                        class="form-check-input"
                        type="radio"
                        :name="`temp_recommend_mode_${recommendIndex + 1}`"
                        :value="'auto'"
                      >
                      <label
                        class="form-check-label"
                        :for="`temp_recommend_modeAuto_${recommendIndex + 1}`"
                      >自動</label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        :id="`temp_recommend_modeManual_${recommendIndex + 1}`"
                        v-model="temp_recommend_data[recommendIndex].mode"
                        class="form-check-input"
                        type="radio"
                        :name="`temp_recommend_mode_${recommendIndex + 1}`"
                        :value="'manual'"
                      >
                      <label
                        class="form-check-label"
                        :for="`temp_recommend_modeManual_${recommendIndex + 1}`"
                      >手動</label>
                    </div>
                  </div>
                  <div
                    v-for="(product, index) in recommend.items"
                    :key="product.prodSerial"
                    class="form-group d-flex align-items-center"
                  >
                    <input
                      v-model="temp_recommend_data[recommendIndex].items[index].prodSerial"
                      type="text"
                      class="form-control form_input"
                      disabled
                    >
                    <input
                      v-model="temp_recommend_data[recommendIndex].items[index].prodName"
                      type="text"
                      class="form-control form_input"
                      disabled
                    >
                    <a
                      href="#"
                      class="circle_btn circle_btn_danger"
                      @click.prevent="delete_recommend_product(recommendIndex, index)"
                    >
                      <i class="icon-minus" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid">
            <div class="section_footer">
              <div
                class="d-flex justify-content-end btn_group"
              >
                <a
                  v-if="product_content === 'create'"
                  href="#"
                  class="btn btn_primary rounded-0"
                  @click.prevent="create_product()"
                >建立商品</a>
                <a
                  v-if="product_content === 'edit'"
                  href="#"
                  class="btn btn_primary rounded-0"
                  @click.prevent="update_product()"
                >儲存商品</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_CATEGORY_DETAIL,
  API_GET_PRODUCT_CATEGORY,
  API_GET_PRODUCT_LIST,
  API_CREATE_PRODUCT,
  API_UPDATE_PRODUCT,
  API_GET_PRODUCT_DETAIL,
  API_ADD_PRODUCT_IMAGE,
  API_GET_PRODUCT_SEARCH,
  API_UPDATE_PRODUCT_SHOW,
  API_UPDATE_PRODUCT_IMAGE,
  API_DELETE_PRODUCT_IMAGE,
  API_UPDATE_PRODUCT_ORDER,
} from '@/plugins/api';
import { ckeditor, MyUploadAdapter } from '@/plugins/ckeditor';
import ProductModal from '@/components/ProductModal.vue';

export default {
  components: {
    draggable,
    ProductModal,
  },
  data() {
    return {
      now_page: 'product',
      dragging: false, /* 拖曳用 */
      search: {
        /* 搜尋資料 */
        system: '',
        main_category: '',
        sub_category: '',
        keyword: '',
      },
      temp_search: [
        /* 建立、 編輯用 */
        {
          system: '',
          categoryCode: '',
          categorySubCode: '',
        },
      ],
      temp_category_data: [
        {
          main_category: [],
          sub_category: [],
        },
      ],
      category_data: [] /* 主次分類資料 */,
      subcategory_data: [] /* 次分類列表 */,
      tab_content: 'list' /* list: 列表; detail: 建立商品、編輯商品  */,
      product_content: '', /* edit: 編輯; create: 建立 */
      product_show_section_list: [
        {
          id: 'basic',
          name: '基本資訊',
        },
        {
          id: 'assist',
          name: '輔助設定',
        },
        {
          id: 'copywriting_mode_gping',
          name: '共用文案',
        },
        {
          id: 'copywriting_mode_wuer',
          name: '專網文案',
        },
        {
          id: 'image',
          name: '圖片設定',
        },
        {
          id: 'recommend',
          name: '推薦設定',
        },
      ],
      product_show_section: 'basic',
      product_list: [] /* 商品列表 */,
      product_detail: {
        /* 商品細節 => 建立、更新用 */
        status: true,
        category: [],
        labelSubCode: [],
        optionShowFront: false,
        optionOutOfStock: false,
        optionPackage: false,
        optionExcludeVipRank: false,
        optionExcludeSaleEvent: false,
        prodSerial: '',
        prodNameErp: '',
        prodName: '',
        prodName2: '',
        barCode: '',
        prodSerialChannel: '',
        price: 0,
        pricePlus: 0,
        standard: '',
        type: '',
        unit: '',
        version: '',
        versionAdminId: '',
        versionDate: '',
        optionTax: '',
        optionComponent: false,
        placeOfOrigin: '',
        preservationCode: '',
        preservationExpMonth: 1,
        transportType: '',
        assignDepotCode: '',
        optionStorageAdj: true,
        storageLimit: 50,
        storageLimitBackend: 200,
        optionDeliveryTimes: 0,
        htmlTitle: '',
        htmlKeywords: '',
        htmlDesc: '',
        dtPublic: new Date().toISOString().substr(0, 10),
        dtUnPublic: '2049-12-31',
        preOrder: [],
        component: {
          calorie: 0,
          protein: 0,
          fat: 0,
          transFat: 0,
          saturatedFat: 0,
          carbohydrate: 0,
          sugar: 0,
          chemistryNa: 0,
        },
        optionMonday: false,
        optionTuesday: false,
        optionWednesday: false,
        optionThursday: false,
        optionSaturday: false,
        optionSunday: true,
        bom: [],
        plus: [
          {
            prodSerialPlus: '',
            quantityLimit: 0,
          },
        ],
        delivery: [],
        excludePayment: [],
        recipeGoods: [],
        recipeCooking: [],
        introduction: '',
        introduction2: '',
        feature: '',
        feature2: '',
        specification: '',
        specification2: '',
        attention: '',
        attention2: '',
        certification: '',
        qa: '',
        images: {},
        recommend: [],
        textNonSchedule: '',
        ediFuguiCode: '',
      },
      product_label_amount: 1 /* 商品標籤預設數量 */,
      product_label_data: [
        /* 商品標籤預設資料型態 */
        {
          labelSubCode: '',
        },
      ],
      analysis_label_amount: 1 /* 分析標籤預設數量 */,
      analysis_label_data: [
        /* 分析標籤預設資料型態 */
        {
          labelSubCode: '',
        },
      ],
      excludePayment: [] /* 排除付款方式資料 */,
      editor: ckeditor.editor,
      editorConfig: ckeditor.editorConfig, /* 編輯器設定 */
      pagination: {
        /* 分頁設定 */
        filter_page_size: 20,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
      },
      temp_recommend_index: 0,
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option', 'nonpoint_payment', 'label']),
    ...mapGetters('product', ['temp_product']),
    /* 避免 vuex 錯誤，轉換成 computed 操作 */
    system_data: {
      get() {
        const vm = this;
        const data = vm.system.slice();
        return data;
      },
    },
    product_label: {
      get() {
        const vm = this;
        let data = [];
        vm.label.forEach((item) => {
          if (item.labelCode === '01') {
            data = item.items;
          }
        });
        return data;
      },
    },
    analysis_label: {
      get() {
        const vm = this;
        let data = [];
        vm.label.forEach((item) => {
          if (item.labelCode === '02') {
            data = item.items;
          }
        });
        return data;
      },
    },
    product_images() {
      const vm = this;
      let data = {
        imageAlbum: [],
        imageAlbum_GH: [],
        image430x430_GH: `${process.env.VUE_APP_API_PATH}/images/image430x430_GH.webp`,
        image430x430_JH: `${process.env.VUE_APP_API_PATH}/images/image430x430_JH.webp`,
        image480x480: `${process.env.VUE_APP_API_PATH}/images/image480x480.png`,
        image640x640: `${process.env.VUE_APP_API_PATH}/images/image640x640.jpg`,
      };
      const imageLength = Object.keys(vm.product_detail.images).length;
      if (imageLength !== 0) {
        data = Object.assign(vm.product_detail.images);
      }
      return data;
    },
    temp_recommend_data: {
      get() {
        const vm = this;
        const data = vm.product_detail.recommend.slice();
        if (vm.product_detail.recommend.length === 0) {
          vm.system.forEach((item) => {
            data.push({
              systemCode: item.systemCode,
              name: item.systemName,
              mode: 'none',
              quantity: 0,
              items: [],
            });
          });
        }
        return data;
      },
      set() {},
    },
    /* 訂單資料分頁化 */
    // filter_Data() {
    //   const vm = this;
    //   const dataLen = vm.product_list.length;
    //   const nowPage = vm.pagination.current_page;
    //   vm.pagination.total_pages = Math.ceil(
    //     dataLen / vm.pagination.filter_page_size,
    //   );
    //   if (nowPage > 1) {
    //     vm.pagination.has_pre = true;
    //   } else {
    //     vm.pagination.has_pre = false;
    //   }
    //   if (nowPage === vm.pagination.total_pages) {
    //     vm.pagination.has_next = false;
    //   } else {
    //     vm.pagination.has_next = true;
    //   }
    //   return vm.product_list.filter(
    //     (item, index) => index < nowPage * vm.pagination.filter_page_size
    //       && index >= (nowPage - 1) * vm.pagination.filter_page_size,
    //   );
    // },
  },
  watch: {
    'product_detail.type': function type(value, oldValue) {
      const vm = this;
      const detail = vm.product_detail;
      if (value !== '預購' && value !== '食譜') {
        detail.preOrder = [];
        detail.recipeGoods = [];
        detail.recipeCooking = [];
      } else if (value === '預購') {
        if (detail.preOrder.length === 0) {
          vm.add_pre_order();
        }
      } else if (value === '食譜' && detail.recipeCooking.length === 0 && detail.recipeCooking.length.recipeGoods === 0) {
        const recipeGoods = {
          prodSerialTarget: '',
          goodsName: '',
          goodsPortions: '',
        };
        const recipeCooking = {
          cookingDesc: '',
        };
        detail.recipeGoods[0] = recipeGoods;
        detail.recipeCooking[0] = recipeCooking;
      }
    },
    'product_detail.optionPackage': function optionPackage(value, oldValue) {
      const vm = this;
      const detail = vm.product_detail;
      if (!value) {
        detail.bom = [];
      } else if (value && detail.bom.length === 0) {
        vm.add_bom();
      }
    },
    /* 監聽商品標籤增減 */
    product_label_amount(value) {
      const vm = this;
      if (vm.product_label_amount === 0) {
        vm.product_label_amount = 1;
        return;
      }
      if (value < vm.product_label_data.length) {
        vm.product_label_data.pop();
        return;
      }
      const lable = {
        labelSubCode: '',
      };
      vm.product_label_data.push(lable);
    },
    /* 監聽分析標籤增減 */
    analysis_label_amount(value) {
      const vm = this;
      if (vm.analysis_label_amount === 0) {
        vm.analysis_label_amount = 1;
        return;
      }
      if (value < vm.analysis_label_data.length) {
        vm.analysis_label_data.pop();
        return;
      }
      const lable = {
        labelSubCode: '',
      };
      vm.analysis_label_data.push(lable);
    },
    temp_product: {
      async handler(newValue, oldValue) {
        const vm = this;
        if (newValue.prodSerial) {
          vm.temp_recommend_data[vm.temp_recommend_index].items.push({
            prodName: newValue.prodName,
            prodSerial: newValue.prodSerial,
          });
          vm.$forceUpdate();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_system_option();
    await vm.get_nonpoint_payment();
    await vm.get_all_label();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', [
      'get_system',
      'get_system_option',
      'get_nonpoint_payment',
      'get_all_label',
    ]),
    /* 切換頁數 */
    // change_page(page = 1) {
    //   const vm = this;
    //   vm.pagination.current_page = page;
    // },
    /* 初始化 validate */
    async init_validate() {
      const vm = this;
      vm.$validator.pause();
      vm.$nextTick(() => {
        vm.$validator.reset();
        vm.$validator.errors.clear('search');
        vm.$validator.errors.clear('detail');
        vm.$validator.fields.items.forEach((field) => field.reset());
        vm.$validator.fields.items.forEach((field) => vm.errors.remove(field));
        vm.$validator.resume();
      });
    },
    /* 初始化 product */
    async initial_product() {
      const vm = this;
      vm.temp_search = [
        /* 建立、 編輯用 */
        {
          system: '',
          categoryCode: '',
          categorySubCode: '',
        },
      ];
      vm.temp_category_data = [
        {
          main_category: [],
          sub_category: [],
        },
      ];
      vm.temp_recommend_data = [
        {
          systemCode: '',
          name: '',
          mode: 'none',
          quantity: 0,
          items: [],
        },
      ];
      vm.product_label_amount = 1;
      vm.product_label_data = [
        {
          labelSubCode: '',
        },
      ];
      vm.analysis_label_amount = 1;
      vm.analysis_label_data = [
        {
          labelSubCode: '',
        },
      ];
      vm.excludePayment = [];
      vm.product_detail = {
        status: true,
        category: [],
        labelSubCode: [],
        optionShowFront: false,
        optionOutOfStock: false,
        optionPackage: false,
        optionExcludeVipRank: false,
        optionExcludeSaleEvent: false,
        prodSerial: '',
        prodName: '',
        barCode: '',
        prodSerialChannel: '',
        price: 0,
        pricePlus: 0,
        standard: '',
        type: '',
        unit: '',
        version: '',
        versionAdminId: '',
        versionDate: '',
        optionTax: '',
        optionComponent: false,
        placeOfOrigin: '',
        preservationCode: '',
        preservationExpMonth: 1,
        transportType: '',
        assignDepotCode: '',
        optionStorageAdj: true,
        optionStorageSync: true,
        storageLimit: 50,
        storageLimitBackend: 200,
        optionDeliveryTimes: 0,
        arriveStrategyCode: '',
        htmlTitle: '',
        htmlKeywords: '',
        htmlDesc: '',
        dtPublic: new Date().toISOString().substr(0, 10),
        dtUnPublic: '2049-12-31',
        preOrder: [],
        component: {
          calorie: 0,
          protein: 0,
          fat: 0,
          transFat: 0,
          saturatedFat: 0,
          carbohydrate: 0,
          sugar: 0,
          chemistryNa: 0,
        },
        optionMonday: false,
        optionTuesday: false,
        optionWednesday: false,
        optionThursday: false,
        optionSaturday: false,
        optionSunday: true,
        bom: [],
        plus: [
          {
            prodSerialPlus: '',
            quantityLimit: 0,
          },
        ],
        delivery: [],
        excludePayment: [],
        recipeGoods: [],
        recipeCooking: [],
        introduction: '',
        introduction2: '',
        feature: '',
        feature2: '',
        specification: '',
        specification2: '',
        attention: '',
        attention2: '',
        certification: '',
        qa: '',
        images: {},
        recommend: [],
        textNonSchedule: '',
        ediFuguiCode: '',
      };
      vm.editor = ckeditor.editor;
      vm.editorConfig = ckeditor.editorConfig;
      vm.temp_recommend_index = 0;
    },
    /* 取得主次分類列表 */
    async get_main_category(value) {
      const vm = this;
      const response = await API_GET_PRODUCT_CATEGORY(value);
      const res = response.data;
      // console.log('取得主次分類列表 成功', res);
      if (res.code === '0000') {
        vm.category_data = res.data;
      }
    },
    /* 篩選次分類列表 */
    async get_sub_category() {
      const vm = this;
      vm.category_data.forEach((item) => {
        if (item.categoryCode === vm.search.main_category) {
          vm.subcategory_data = item.items;
        }
      });
    },
    /* 取得主次分類列表 => 多組分類時專用 */
    async get_temp_main_category(value, index) {
      const vm = this;
      const response = await API_GET_PRODUCT_CATEGORY(value);
      const res = response.data;
      // console.log('取得主次分類列表 多組專用 成功', res);
      if (res.code === '0000') {
        vm.temp_category_data[index].main_category = res.data;
      }
    },
    /* 篩選次分類列表 => 多組分類專用 */
    async get_temp_sub_category(value, index) {
      const vm = this;
      vm.temp_category_data[index].main_category.forEach((item) => {
        if (item.categoryCode === vm.temp_search[index].categoryCode) {
          vm.temp_category_data[index].sub_category = item.items;
        }
      });
    },
    /* 新增商品分類 */
    async add_product_category() {
      const vm = this;
      const category = {
        system: '',
        categoryCode: '',
        categorySubCode: '',
      };
      const categoryData = {
        main_category: [],
        sub_category: [],
      };
      vm.temp_search.push(category);
      vm.temp_category_data.push(categoryData);
    },
    /* 刪除商品分類 */
    async delete_product_category(index) {
      const vm = this;
      vm.temp_search.splice(index, 1);
      vm.temp_category_data.splice(index, 1);
    },
    /* 刪除推薦商品 */
    async delete_recommend_product(sectionIndex, productIndex) {
      const vm = this;
      vm.temp_recommend_data[sectionIndex].items.splice(productIndex, 1);
      vm.$forceUpdate();
    },
    /* 新增排除付款方式 */
    async add_exclude_payment() {
      const vm = this;
      const payment = {
        paymentCode: '',
      };
      vm.excludePayment.push(payment);
    },
    /* 刪除排除付款方式 */
    async delete_exclude_payment(index) {
      const vm = this;
      vm.excludePayment.splice(index, 1);
    },
    /* 新增組合商品 */
    async add_bom() {
      const vm = this;
      const bom = {
        prodItemSerial: '',
        prodQunatity: 0,
      };
      vm.product_detail.bom.push(bom);
    },
    /* 刪除組合商商品 */
    async delete_bom(index) {
      const vm = this;
      vm.product_detail.bom.splice(index, 1);
    },
    /* 新增預購時間 */
    async add_pre_order() {
      const vm = this;
      const date = {
        dtArrive: '',
        dtStart: '',
        dtEnd: '',
      };
      vm.product_detail.preOrder.push(date);
    },
    /* 新增食材 */
    async add_recipe_goods() {
      const vm = this;
      const recipeGoods = {
        prodSerialTarget: '',
        goodsName: '',
        goodsPortions: '',
      };
      vm.product_detail.recipeGoods.push(recipeGoods);
    },
    /* 新增食譜做法 */
    async add_recipe_cooking() {
      const vm = this;
      const recipeCooking = {
        cookingDesc: '',
      };
      vm.product_detail.recipeCooking.push(recipeCooking);
    },
    /* 刪除預購時間 */
    async delete_pre_order(index) {
      const vm = this;
      vm.product_detail.preOrder.splice(index, 1);
    },
    /* 刪除食譜食材 */
    async delete_recipe_goods(index) {
      const vm = this;
      vm.product_detail.recipeGoods.splice(index, 1);
    },
    /* 刪除食譜做法 */
    async delete_recipe_cooking(index) {
      const vm = this;
      vm.product_detail.recipeCooking.splice(index, 1);
    },
    /* 新增配送商品 */
    async add_delivery() {
      const vm = this;
      const delivery = {
        optionProdSerial: '',
        optionDays: '',
        optionTimes: '',
      };
      vm.product_detail.delivery.push(delivery);
    },
    /* 刪除配送商品 */
    async delete_delivery(index) {
      const vm = this;
      vm.product_detail.delivery.splice(index, 1);
    },
    /* 取得商品列表 */
    async get_product_list() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('search');
      if (validateResult) {
        const response = await API_GET_PRODUCT_LIST(vm.search.sub_category);
        const res = response.data;
        // console.log('取得商品列表 成功', res);
        if (res.code === '0000') {
          vm.product_list = res.data;
        }
        // vm.pagination = {
        //   filter_page_size: 20,
        //   total_pages: 1,
        //   current_page: 1,
        //   has_pre: false,
        //   has_next: false,
        // };
      }
    },
    /* 搜尋商品 */
    async search_product() {
      const vm = this;
      const validateResult = vm.search.keyword !== '';
      if (validateResult) {
        const data = {
          mode: '1',
          value: vm.search.keyword,
        };
        const response = await API_GET_PRODUCT_SEARCH(data);
        const res = response.data;
        // console.log('取得商品列表 成功', res);
        if (res.data.length === 0) {
          vm.$swal({
            icon: 'warning',
            title: '查無相關商品!',
            text: '請確認關鍵字!',
            confirmButtonText: '關閉視窗',
          });
        }
        // vm.pagination = {
        //   filter_page_size: 20,
        //   total_pages: 1,
        //   current_page: 1,
        //   has_pre: false,
        //   has_next: false,
        // };
        vm.product_list = res.data;
      } else {
        vm.$swal({
          icon: 'error',
          title: '欄位不得為空!',
          timer: 1000,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 建立商品 */
    async create_product() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      const detail = vm.product_detail;
      if (validateResult) {
        detail.category = [];
        detail.labelSubCode = [];
        detail.excludePayment = [];
        vm.temp_search.forEach((item) => {
          const data = {
            categorySubCode: item.categorySubCode,
            optionPrimary: item.optionPrimary,
          };
          detail.category.push(data);
        });
        vm.product_label_data.forEach((item) => {
          detail.labelSubCode.push(item.labelSubCode);
        });
        vm.analysis_label_data.forEach((item) => {
          detail.labelSubCode.push(item.labelSubCode);
        });
        vm.excludePayment.forEach((item) => {
          detail.excludePayment.push(item.paymentCode);
        });
        if (detail.recipeGoods.length > 0) {
          detail.recipeGoods.forEach((item, index) => {
            item.optionPriority = index + 1;
          });
        }
        if (detail.recipeCooking.length > 0) {
          detail.recipeCooking.forEach((item, index) => {
            item.optionPriority = index + 1;
          });
        }
        const response = await API_CREATE_PRODUCT(detail);
        const res = response.data;
        if (res.code === '0000') {
          await vm.upload_picture();
          await vm.get_product_list();
          vm.tab_content = 'list';
          vm.product_show_section = 'basic';
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.initial_product();
          await vm.init_validate();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新商品 */
    async update_product() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('detail');
      const detail = vm.product_detail;
      if (validateResult) {
        detail.category = [];
        detail.labelSubCode = [];
        detail.excludePayment = [];
        vm.temp_search.forEach((item) => {
          const data = {
            categorySubCode: item.categorySubCode,
            optionPrimary: item.optionPrimary,
          };
          detail.category.push(data);
        });
        vm.temp_recommend_data.forEach((item) => {
          const recommendItemData = [];
          item.items.forEach((recommendItem) => {
            recommendItemData.push(recommendItem.prodSerial);
          });
          item.items = recommendItemData;
        });
        vm.excludePayment.forEach((item) => {
          detail.excludePayment.push(item.paymentCode);
        });
        vm.product_label_data.forEach((item) => {
          detail.labelSubCode.push(item.labelSubCode);
        });
        vm.analysis_label_data.forEach((item) => {
          detail.labelSubCode.push(item.labelSubCode);
        });
        if (detail.recipeGoods.length > 0) {
          detail.recipeGoods.forEach((item, index) => {
            item.optionPriority = index + 1;
          });
        }
        if (detail.recipeCooking.length > 0) {
          detail.recipeCooking.forEach((item, index) => {
            item.optionPriority = index + 1;
          });
        }
        const response = await API_UPDATE_PRODUCT(detail);
        const res = response.data;
        if (res.code === '0000') {
          await vm.upload_picture();
          await vm.get_product_list();
          vm.tab_content = 'list';
          vm.product_show_section = 'basic';
          vm.$swal({
            icon: 'success',
            title: '成功!',
            text: response.data.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.initial_product();
          await vm.init_validate();
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新分類顯示 */
    async update_product_optionshow(prodSerial, optionName, status) {
      const vm = this;
      vm.$store.dispatch('page_load', true);
      const data = {
        prodSerial,
        optionName,
        status: !status,
      };
      const response = await API_UPDATE_PRODUCT_SHOW(data);
      const res = response.data;
      // console.log('更新商品顯示 成功', response);
      if (res.code === '0000') {
        await vm.get_product_list();
      }
      vm.$store.dispatch('page_load', false);
    },
    /* 取得商品細節 */
    async get_product_detail(prodId) {
      const vm = this;
      const response = await API_GET_PRODUCT_DETAIL(prodId);
      const res = response.data;
      // console.log('取得商品細節 成功', res);
      if (res.code === '0000') {
        vm.tab_content = 'detail';
        vm.product_content = 'edit';
        vm.product_detail = res.data;
        const detail = vm.product_detail;
        if (detail.plus.length > 0) {
          const { plus } = detail;
          plus.forEach((item) => {
            delete item.serialNumber;
          });
        }
        vm.temp_search = [];
        vm.temp_category_data = [];
        vm.temp_recommend_data = detail.recommend;
        for (let index = 0; index < detail.category.length; index += 1) {
          const categoryData = {
            main_category: [],
            sub_category: [],
          };
          vm.temp_category_data.push(categoryData);
          const response = await API_GET_CATEGORY_DETAIL(
            detail.category[index].categoryCode,
          );
          const res = response.data.data;
          const searchData = {
            categoryCode: detail.category[index].categoryCode,
            categorySubCode: detail.category[index].categorySubCode,
            system: res.systemCode,
            optionPrimary: detail.category[index].optionPrimary,
          };
          vm.temp_search.push(searchData);
          await vm.get_temp_main_category(res.systemCode, index);
          await vm.get_temp_sub_category(res.categoryCode, index);
        }
        detail.recommend.forEach((recommendItem) => {
          vm.system_data.forEach((systemItem) => {
            if (recommendItem.systemCode === systemItem.systemCode) {
              recommendItem.name = systemItem.systemName;
            }
          });
        });
        detail.label.forEach((item) => {
          const data = {};
          if (item.labelCode === '01') {
            data.labelSubCode = item.labelSubCode;
            vm.product_label_data = [];
            vm.product_label_data.push(data);
            vm.product_label_amount = vm.product_label_data.length;
          } else if (item.labelCode === '02') {
            data.labelSubCode = item.labelSubCode;
            vm.analysis_label_data = [];
            vm.analysis_label_data.push(data);
            vm.analysis_label_amount = vm.analysis_label_data.length;
          }
        });
        detail.excludePayment.forEach((item) => {
          const data = {};
          data.paymentCode = item.paymentCode;
          vm.excludePayment.push(data);
        });
        vm.$set(detail, 'transportType', detail.storageSetting.transportType);
        vm.$set(detail, 'assignDepotCode', detail.storageSetting.assignDepotCode);
        vm.$set(detail, 'optionStorageAdj', detail.storageSetting.optionStorageAdj);
        vm.$set(detail, 'optionStorageSync', detail.storageSetting.optionStorageSync);
        vm.$set(detail, 'storageLimit', detail.storageSetting.storageLimit);
        vm.$set(detail, 'storageLimitBackend', detail.storageSetting.storageLimitBackend);
        vm.$set(detail, 'feature', detail.text.feature);
        vm.$set(detail, 'feature2', detail.text.feature2);
        vm.$set(detail, 'specification', detail.text.specification);
        vm.$set(detail, 'specification2', detail.text.specification2);
        vm.$set(detail, 'attention', detail.text.attention);
        vm.$set(detail, 'attention2', detail.text.attention2);
        vm.$set(detail, 'introduction', detail.text.introduction);
        vm.$set(detail, 'introduction2', detail.text.introduction2);
        vm.$set(detail, 'certification', detail.text.certification);
        vm.$set(detail, 'qa', detail.text.qa);
        vm.$set(detail, 'htmlTitle', detail.text.htmlTitle);
        vm.$set(detail, 'htmlKeywords', detail.text.htmlKeywords);
        vm.$set(detail, 'htmlDesc', detail.text.htmlDesc);
        vm.$set(detail, 'optionMonday', detail.excludeWeekly.optionMonday);
        vm.$set(detail, 'optionTuesday', detail.excludeWeekly.optionTuesday);
        vm.$set(detail, 'optionWednesday', detail.excludeWeekly.optionWednesday);
        vm.$set(detail, 'optionThursday', detail.excludeWeekly.optionThursday);
        vm.$set(detail, 'optionFriday', detail.excludeWeekly.optionFriday);
        vm.$set(detail, 'optionSaturday', detail.excludeWeekly.optionSaturday);
        vm.$set(detail, 'optionSunday', detail.excludeWeekly.optionSunday);
        vm.$set(detail, 'labelSubCode', detail.label);
        delete detail.adminId;
        delete detail.storage;
        delete detail.storageSetting;
        delete detail.text;
        delete detail.excludeWeekly;
        delete detail.label;
      }
    },
    async readURL(e, input) {
      const targetId = e.target.name !== '' ? e.target.name : e.target.files[0].name;
      if (input.files.length > 0) {
        input.files.forEach((item) => {
          const reader = new FileReader();
          reader.onload = function onload(eDetail) {
            const img = $(e.target).siblings('.display_image').find('img');
            $(img).attr('src', eDetail.target.result);
          };
          reader.readAsDataURL(item);
        });
      }
    },
    /* 新增商品相簿 */
    async add_image_album() {
      const vm = this;
      const imageAlbum = {
        filename: '',
        url: `${process.env.VUE_APP_API_PATH}/images/xxx.jpg`,
      };
      vm.product_images.imageAlbum.push(imageAlbum);
    },
    /* 新增商品相簿，保健用 */
    async add_image_album_gh() {
      const vm = this;
      const imageAlbumGH = {
        filename: '',
        url: `${process.env.VUE_APP_API_PATH}/images/xxx.webp`,
      };
      vm.product_images.imageAlbum_GH.push(imageAlbumGH);
    },
    /* 上傳商品圖片 */
    async upload_picture() {
      const vm = this;
      const productImageData = Array.from(
        document.querySelectorAll('.form_input_image > input'),
      );
      const productImageAlbumData = Array.from(
        document.querySelectorAll('.form_input_imageAlbum > input'),
      );
      const productImageAlbumDataGH = Array.from(
        document.querySelectorAll('.form_input_imageAlbumGH > input'),
      );
      const data = new FormData();
      data.append('prodSerial', vm.product_detail.prodSerial);
      data.append('imageArticle', []);
      productImageData.forEach((image) => {
        data.append(image.dataset.name, image.files[0]);
      });
      productImageAlbumData.forEach((image) => {
        if (image.files.length > 0) {
          const item = image.files[0] || {};
          const name = image.dataset.name === '' ? image.files[0].name : image.dataset.name;
          data.append('imageAlbum', image.files[0], name);
        }
      });
      productImageAlbumDataGH.forEach((image) => {
        if (image.files.length > 0) {
          const item = image.files[0] || {};
          const name = image.dataset.name === '' ? image.files[0].name : image.dataset.name;
          data.append('imageAlbum_GH', image.files[0], name);
        }
      });
      await API_ADD_PRODUCT_IMAGE(data);
    },
    /* 更新圖片排序 */
    async update_image(image) {
      const vm = this;
      if (image.newfilename === undefined) {
        vm.$swal({
          icon: 'warning',
          title: '警告!',
          text: '請輸入順位',
          confirmButtonText: '關閉視窗',
        });
        return;
      }
      await vm.$store.dispatch('page_load', true);
      const id = vm.product_detail.prodSerial;
      const data = {
        prodSerial: vm.product_detail.prodSerial,
        mode: 'album',
        filename: image.filename,
        priority: Number(image.newfilename),
      };
      const response = await API_UPDATE_PRODUCT_IMAGE(data);
      const res = response.data;
      // console.log('更新圖片檔名 成功', response);
      if (res.code === '0000') {
        await vm.initial_product();
      }
      setTimeout(() => {
        vm.get_product_detail(id);
        vm.$store.dispatch('page_load', false);
      }, 1500);
    },
    /* 刪除圖片 */
    async delete_image(image, index) {
      const vm = this;
      const id = vm.product_detail.prodSerial;
      await vm.$store.dispatch('page_load', true);
      if (image.filename !== '') {
        const data = {
          prodSerial: vm.product_detail.prodSerial,
          mode: 'album',
          filename: image.filename,
        };
        const response = await API_DELETE_PRODUCT_IMAGE(data);
        const res = response.data;
        // console.log('刪除圖片 成功', response);
        if (res.code === '0000') {
          await vm.initial_product();
        }
        setTimeout(() => {
          vm.get_product_detail(id);
          vm.$store.dispatch('page_load', false);
        }, 1500);
      } else {
        vm.product_images.imageAlbum.splice(index, 1);
        vm.$store.dispatch('page_load', false);
      }
    },
    /* 更新商品排序 */
    async update_product_order({ moved }) {
      const vm = this;
      await vm.$store.dispatch('page_load', true);
      const data = {
        categorySubCode: vm.search.sub_category,
        prodSerial: moved.element.prodSerial,
        number: moved.newIndex + 1,
      };
      const response = await API_UPDATE_PRODUCT_ORDER(data);
      const res = response.data;
      if (res.code === '0000') {
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          timer: 1000,
          confirmButtonText: '關閉視窗',
        });
        await vm.get_product_list();
      }
      await vm.$store.dispatch('page_load', false);
    },
    /* ckeditor 上傳圖片 */
    upload_article_image(editor) {
      const vm = this;
      // 自定義上傳圖片插件
      editor.plugins.get('FileRepository').createUploadAdapter = (loader) => new MyUploadAdapter(vm.product_detail.prodSerial, loader);
    },
    async toggle_modal(index) {
      const vm = this;
      vm.temp_recommend_index = index;
      await vm.$store.dispatch('product/toggle_modal');
    },
  },
};
</script>
